/*body {*/
/*  margin: 0;*/
/*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
/*    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
/*    sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*}*/

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/
.ant-input-password-icon {
    color: #1890ff !important;
    text-decoration: underline;
    font-size: small;
    font-weight: bold;
}
.ant-form-item-label {
    font-size: 12px !important; /* Adjust the font size as needed */
  }

@tailwind base;
@tailwind components;
@tailwind utilities;
.text-blue {
    color: #0079D6;
}

.ant-layout-sider-zero-width-trigger {
    background-color: #0079D6;

}

.ant-layout-sider-children {
    overflow-y: auto;
}

.bg-blue {
    background-color: #0079D6;
}

.bg-green {
    background-color: #57BF6F;
}

.bg-red {
    background-color: #C63F17;
}

#root {
    height: 100vh;
    overflow: hidden;
}

.ant-drawer-close {
    right: unset;
}

.ant-drawer-body {
    padding: unset;
}

.hide-scroll::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}


.ant-btn-success {
    color: #fff;
    background: #04c330;
    border-color: #04c330;
}


.row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
    z-index: 1000;
}

.row-dragging td {
    padding: 16px;
}

.row-dragging .drag-visible {
    visibility: visible;

}

.ant-upload-list-picture-container {
    float: left;
    padding-right: 2px;
}
.ant-timeline-item{
    margin-left: -10em;
}

.ant-menu-item a {
    color: gray;
    font-weight: normal;
}

.ant-menu-item-group-title {
    color: gray;
    font-weight: normal;

}